.slider{
    width: 100%;
    &__item{
        height: 25vw;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        @include mq($until: lg) {
            height: 35vw;
        }
        @include mq($until: xs) {
            height: 60vw;
        }
        &--home{
            border-radius: 13px;
        }
    }
    &--home{
        margin: 30px 0;
    }
}