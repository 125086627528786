.TickerNews{
    width: 100%;
    height: 60px;
    line-height: 60px;
    margin-bottom: 30px;
    @include mq($until: xs) {
        margin-bottom: 15px;
    }
}
.ti_wrapper{
    width: 100%;
    position: relative;
    overflow: hidden;
    height: 60px;
    background-color: $color-main;
    border-radius: 8px;
    position: relative;

    @include mq($until: xs) {
        height: 40px;
    }

    &::before, &::after{
        content: '';
        position: absolute;
        width: 10%;
        height: 100%;
        z-index: 1;
        display: none;
    }
    &::before{
        left: 0;
        background: linear-gradient(to right, $color-main 0%, $color-main 25%, transparent 100%);
    }
    &::after{
        right: 0;
        background: linear-gradient(to left, $color-main 0%, $color-main 25%, transparent 100%);
    }
}
.ti_slide{
    width: 30000px;
    position: relative;
    left: 0;
    top: 0;
}
.ti_content{
    width: 8000px;
    position: relative;
    float:left;
}
.ti_news{
    line-height: 60px;
    float:left;
    @include mq($until: xs) {
        line-height: 40px;
    }
    a {
        font-family: $font-family-extra;
        display: inline-block;
        color: $color-font-white;
        letter-spacing: 1px;
        &:hover {
            color: rgba(255, 255, 255, 0.6);
        }
    }
    span{
        color: $color-font-white;
        margin: 0 15px;
        display: inline-block;
    }
}