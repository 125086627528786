.wcag{
    display: flex;
    align-items: center;
    line-height: 1;
    &__fonts{
        display: flex;
        align-items: flex-end;
    }
    &__font{
        font-weight: $font-weight-bold;
        color: $color-font-black;
        margin-left: rem(7px);
        line-height: 1;
        &:hover{
            color: $color-font-white;
        }
        &--small{
            font-size: 0.9rem;
        }
        &--big{
            font-size: 1.1rem;
        }
        &--active{
            color: #ffffff;
        }
    }
    &__contrast{
        display: flex;
        align-items: center;
        margin-left: rem(20px);
        transition: filter .3s ease-in-out;
        line-height: 1;
        &:hover{
            filter: invert(1);
        }
    }
}