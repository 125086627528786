// =========================
// Fonts
// =========================

 @font-face {
     font-family: 'BreeSerif';
     font-weight: 400;
     src: url('../../fonts/BreeSerif-Regular.ttf');
 }

@font-face {
    font-family: 'Lato';
    font-weight: 400;
    src: url('../../fonts/Lato-Regular.ttf');
}
@font-face {
    font-family: 'Lato';
    font-weight: 700;
    src: url('../../fonts/Lato-Bold.ttf');
}