.news{
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-radius: 8px;
    border: 1px solid $color-extra;
    transition: border .3s ease-in-out, box-shadow .3s ease-in-out;
    max-width: 355px;
    @include mq($until: lg){
        max-width: unset;
    }
    &:hover{
        border: solid 1px transparent;
        box-shadow: 0 0 15px rgba(0,0,0,0.1);
        .news__more{
            color: $color-main;
        }
    }
    &__video{
        background-color: #000;
        video, iframe{
            width: 100%;
            height: 12.5vw;
            @include mq($until: lg){
                height: 23vw;
            }
            @include mq($until: xs){
                height: 43vw;
            }
        }
    }
    &__image{
        height: 12.5vw;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center center;
        @include mq($until: lg) {
            height: 23vw;
        }
        @include mq($until: xs) {
            height: 43vw;
        }
    }
    &__content{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: rem(30px) rem(25px);
        height: calc(100% - 12.5vw);
        @include mq($until: lg){
            height: calc(100% - 23vw);
        }
        @include mq($until: xs){
            height: calc(100% - 43vw);
        }
    }
    &__title{
        font-size: rem(18px);
        text-transform: uppercase;
        margin-bottom: 30px;
    }
    &__excerpt{
        color: $color-font-black;
        font-size: rem(15px);
        line-height: 1.6;
        &:last-of-type{
            margin-bottom: rem(40px);
        }
        &--video:last-of-type{
            margin-bottom: 0;
        }
    }
    &__footer{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: auto;
    }
    &__date{
        color: $color-font-black;
        font-size: rem(15px);
    }
    &__more{
        font-family: $font-family-extra;
        font-size: rem(15px);
        color: $color-font-black;
        transition: color .3s ease-in-out;
    }
}