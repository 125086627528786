.partners{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    @include mq($until: md) {
        flex-wrap: wrap;
        margin-top: 20px;
    }

    &__link{
        display: inline-block;
        margin-left: 30px;
        max-width: 150px;

        @include mq($until: lg) {
            &:nth-child(1){
                margin-left: 0;
            }
        }
        @include mq($until: sm) {
            max-width: 100px;
            margin-left: 15px;
        }
        @include mq($until: xs) {
            width: 44%;
            text-align: center;
            max-width: unset;
            margin-bottom: 30px;
        }

        img{
            transition: transform .3s ease-in-out;
            max-height: 90px;
        }
        &:hover img{
            transform: scale(0.95);
        }
    }
}