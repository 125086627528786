.job{
    &__title-wrapper{
        margin-bottom: 30px;
    }
    &__title{
        margin-bottom: 0;
    }
    &__status{
        font-size: 1rem;
        color: green;
        &--deactive{
            color: $color-main;
            font-weight: 600;
        }
    }
    &__description{
        margin-top: 30px;
        background-color: $color-extra;
        border-radius: 8px;
        padding: 2rem 1.5rem;
        border: solid 1px #cecdcd;
        font-weight: 600;
    }
    &__rodo{
        margin-top: 50px;
        font-size: 0.85rem;
        li{
            color: #8f8f8f;
        }
    }
    &__contact{
        font-style: italic;
        font-weight: 400;
        font-size: 0.95rem;
    }
}