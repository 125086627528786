.searcher{
  display: flex;
  justify-content: flex-end;
  &__button{
    width: 40px;
    height: 40px;
    background-color: $color-main;
    outline: none;
    box-shadow: none;
    border: none;
    cursor: pointer;
    transition: background-color .3s ease-in-out;
    i{
      color: #fff;
    }
    &:hover{
      background-color: $color-font-black;
    }
  }
  &__input{
    outline:none !important;
    border: solid 1px $color-main;
    padding: 5px 10px;
    display: none;
  }
}