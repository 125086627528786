.blood{
    position: relative;
    &::before{
        content: '';
        width: 100%;
        background-color: $color-extra;
        height: 75%;
        border-bottom: 1px solid #cecdcd;
        position: absolute;
        z-index: -1;
    }
    &__top{
        display: flex;
        justify-content: space-between;
        min-height: 90px;
        margin-top: 25px;
        @include mq($until: xl){
            flex-wrap: wrap;
            margin-top: 15px;
        }
    }
    &__day{
        display: flex;
        align-items: center;
        font-family: $font-family-extra;
        @include mq($until: xl){
            order: 1;
            width: 50%;
        }
        @include mq($until: sm) {
            width: 100%;
            margin-bottom: 15px;
        }
        @include mq($until: xs) {
            justify-content: center;
        }

        &-label{
            color: #000;
            text-transform: uppercase;
            font-size: rem(16px);
        }
        &-value{
            color: $color-font-white;
            background-color: $color-main;
            padding: 5px 10px;
            margin-left: 10px;
            border-radius: 5px;
            font-size: rem(16px);
        }
    }
    &__legend{
        display: flex;
        @include mq($until: xl){
            order: 3;
            width: 100%;
            justify-content: center;
            margin-top: 15px;
        }
        @include mq($until: xs) {
            flex-direction: column;
            align-items: center;
        }
        &-item{
            display: flex;
            align-items: center;
            margin-right: 25px;
            @include mq($until: xs) {
                margin-right: 0;
            }
            &:last-of-type{
                margin-right: 0;
            }
        }
        &-label{
            color: $color-font-black;
            font-size: rem(15px);
            margin-right: 10px;
            @include mq($until: sm) {
                font-size: 0.85rem;
                margin-right: 5px;
            }
            @include mq($until: xs) {
                font-size: 1rem;
                min-width: 100px;
            }
        }
        &-value{
            max-width: 35px;
        }
    }
    &__logo{
        display: flex;
        align-items: center;
        @include mq($until: xl){
            order: 2;
            width: 50%;
            justify-content: flex-end;
        }
        @include mq($until: xs) {
            width: 100%;
        }
    }
    &__stats-wrapper{
        display: flex;
        justify-content: space-between;
        @include mq($until: sm) {
            flex-wrap: wrap;
        }
    }
    &__stats{
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #cecdcd;
        border-radius: 10px;
        padding: rem(30px) rem(50px);
        background-color: #fff;
        width: 45%;
        margin-top: 15px;

        @include mq($until: lg) {
            width: 49%;
            padding: 20px;
        }
        @include mq($until: sm) {
            width: 100%;
        }
        @include mq($until: xs) {
            flex-wrap: wrap;
        }
    }
    &__group {
        @include mq($until: xs) {
            width: 100%;
            text-align: center;
            margin-bottom: 15px;
        }
        span{
            font-family: $font-family-extra;
            color: $color-main;
            font-size: rem(18px);
        }
    }
    &__state{
        display: flex;
        flex-direction: column;
        @include mq($until: xs) {
            max-width: 25%;
        }
        span{
            font-size: rem(14px);
            text-align: center;
            color: $color-font-grey;
            margin-bottom: 10px;
        }
    }

}