.footer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;
    @include mq($until: lg) {
        padding-top: 30px;
        padding-bottom: 30px;
    }
    @include mq($until: xs) {
        flex-direction: column;
        justify-content: center;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    span, a{
        color: $color-font-black;
        font-size: rem(14px);
        transition: color .3s ease-in-out;
        @include mq($until: xs) {
            margin-bottom: 10px;
        }
    }
    a:hover{
        color: $color-main;
        span{
            color: $color-main;
        }
    }
    &__ssi{
        display: flex;
        align-items: center;
        span{
            margin-right: 15px;
            @include mq($until: xs) {
                margin-bottom: 0;
                margin-right: 5px;
            }
        }
    }
}