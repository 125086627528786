.organizations{
    border-bottom: solid 3px $color-extra;
    padding-bottom: 35px;
    padding-top: 55px;

    &__logos{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 50px;
        @include mq($until: sm) {
            flex-wrap: wrap;
            justify-content: center;
            margin-bottom: 0;
        }
    }
    &__logo{
        @include mq($until: md) {
            max-width: 150px;
        }
        @include mq($until: sm) {
            margin-right: 30px;
            margin-bottom: 30px;
        }
        img{
            transition: transform .3s ease-in-out;
        }
        &:hover img{
            transform: scale(0.95);
        }
    }
    &__text p{
        font-size: rem(16px);
        text-align: center;
        color: $color-font-black;
        @include mq($until: xl){
            font-size: 0.9rem;
        }
    }
}