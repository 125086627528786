.header__menuItem:nth-child(1) .header__submenu,
.header__menuItem:nth-child(2) .header__submenu{
    right: unset;
    left: 0;
}
.header__submenu{
    position: absolute;
    display: flex;
    flex-direction: column;
    background: $color-extra;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: unset;
    right: 0;
    top: 100%;
    padding: 0;
    height: auto;
    max-height: 900px;
    opacity: 1;
    z-index: 9;
    transform: scaleY(1);
    transform-origin: top center;
    transition: opacity .6s ease-in-out, transform .3s ease-in-out;
    min-width: 300px;
    box-shadow: 1px 10px 20px 0px rgba(0, 0, 0, 0.3);
    &--hidden{
        opacity: 0;
        transform: scaleY(0);
    }
    .header__menuItem{
        width: auto;
        margin: rem(10px);
    }
    .header__menuLink{
        min-height: unset;
        color: $color-font-grey;
        background-color: $color-extra;
        padding: 0;
        font-size: rem(17px);
        &:hover{
            color: $color-main;
        }
    }
}