.button{
    display: inline-block;
    width: auto;
    border: solid 1px $color-font-black;
    padding: rem(15px) rem(30px);
    border-radius: 5px;
    transition: border .3s ease-in-out, color .3s ease-in-out;
    &:hover{
        border: solid 1px $color-main;
        .button__name{
            color: $color-main;
        }
    }

    &--withIcon{
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: rem(230px);
        border: solid 1px $color-extra;
        border-radius: 5px;
        padding: rem(10px) rem(15px);
        transition: border .3s ease-in-out, box-shadow .3s ease-in-out;
        &:not(:last-of-type){
            margin-right: 10px;
        }
        &:hover{
            border: solid 1px transparent;
            box-shadow: 0 0 10px rgba(0,0,0,0.1);
        }
        @include mq ($until: xxl){
            .button__name{
                font-size: rem(13px);
            }
        }
    }

    &__name{
        font-family: $font-family-extra;
        color: $color-font-black;
        transition: color .3s ease-in-out;
        font-size: rem(15px);

    }

    &__icon{
        margin-right: rem(15px);
        @include mq ($until: xxl){
            max-width: 65px;
        }
        @include mq ($until: xxl){
            margin-right: rem(10px);
        }
        @include mq($until: xs) {
            margin-right: 15px;
        }
    }
}