.donation__before {
  margin-top: 30px;
}

.donation__info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;

  @include mq($until: sm) {
    display: block;
  }
}

.bold {
  font-weight: 600;
}

.calc {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  background-color: $color-extra;
  padding: 20px;
  border-radius: 15px;

  @include mq($until: md) {
    display: grid;
  }

  @include mq($until: sm) {
    display: block;
  }

  &__donation-type {
    color: $color-main;
    font-size: 1.5rem;
    margin-bottom: 10px;
  }

  &__donation-header {
    display: flex;
    flex-direction: column;
    label,input, button {
      margin-bottom: 5px;
    }

    button {
      color: white;
      background-color: $color-main;
      display: inline-block;
      padding: 10px 15px;
      margin-top: auto;
      transition: all .3s;
      border: 1px solid white;

      &:hover {
        color: $color-main;
        background-color: white;
        cursor: pointer;

      }
    }
  }

  &__next-date {
    margin-top: 20px;
    background-color: $color-extra;
    padding: 20px 40px;
    text-align: center;
    font-size: 1.5rem;
    transform: scaleY(0);
    border-radius: 15px;

    @include mq($until: sm) {
      padding: 5px;
      font-size: 1rem;
    }

    span {
      font-size: inherit;
      color: $color-main;
    }

    &--show {
      transform: scaleY(1);
    }
  }

  &__gender {
    color: $color-main;
    &--hidden {
      display: none;
    }
  }
}