.modal-dialog {
    max-width: 990px;
    min-height: 450px;
    margin-top: 100px;
}
.modal-content{
    border: none;
    border-radius: 8px;
    overflow: hidden;
}
.modal-body {
    padding: 315px 20% 60px 20%;
    position: relative;
    background-image: url('../../images/pop-up.png');
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-color: #e9e8e8;
    text-align: center;
    h2{
        color: $color-main;
    }
}
.modal-content button.close {
    text-shadow: none;
    outline: none !important;
    top: 10px;
    right: 10px;
    position: absolute;
    border: transparent;
    border-radius: 100%;
    background: #e9e8e8;
    cursor: pointer;
    font-size: 20px;
}
@media screen and (max-width: 1499px) {
    .modal-dialog { margin-top: 50px;}
}
@media screen and (max-width: 1199px) {
    .modal-dialog { width: 75%;}
    .modal-body {padding: 24vw 10% 60px 10%;}
}
@media screen and (max-width: 767px) {
    .modal-dialog { width: 100%; }
    .modal-body {padding: 30vw 10% 30px 10%;}
}