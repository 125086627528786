*, *::before, *::after {
    box-sizing: border-box;
}
body.high-contrast{
    background-color: #000;
    color: #fff;
    h1,h2,h3,h4,p,div,span,a,nav, ul, li, i{
        color: #fff !important;
        background-color: #000 !important;
    }
    .wcag__contrast, .header__logo{
        img{
            filter: invert(1);
        }
    }

}
.site-wrapper{
    display: flex;
    flex-flow: column;
    min-height: 100vh;
    @include mq($until: lg){overflow: hidden;}
}
.padding-container{
    padding: 0 200px;
    @include mq($until: xxl){padding: 0 100px;}
    @include mq($until: xl){padding: 0 5vw;}
    @include mq($until: md) {padding: 0 30px;}
    @include mq($until: xs) {padding: 0 15px;}
}

footer{
    margin-top: auto;
}
.no-gutters{
    & > .col-left{
        padding-right: 30px;
        @include mq($until: md) {padding-right: 0;}
    }
    & > .col-right{
        padding-left: 30px;
        @include mq($until: md) {padding-left: 0;}
    }
}
.col-left, .col-right, col-inside{
    margin-bottom: 30px;
}