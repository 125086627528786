.contact{
    &--top{
        display: flex;
        flex-direction: column;
        min-height: 55px;
        justify-content: space-between;
        @include mq($until: lg){
            width: 60%;
        }
        @include mq($until: md) {
            width: 50%;
        }
        @include mq($until: xs) {
            width: 100%;
            margin-bottom: 15px;
        }
    }
    &__header{
        height: 5vw;
        margin: 0;
        display: flex;
        align-items: center;
        @include mq($until: xs) {
            margin-bottom: 15px;
        }
    }
    &__wrapper{
        position: relative;
        background-color: $color-extra;
        display: flex;
        justify-content: flex-start;
        padding-top: 50px;
        padding-bottom: 50px;
        @include mq($until: md) {
            flex-wrap: wrap;
        }
        @include mq($until: xs) {
            padding-top: 20px;
            padding-bottom: 20px;
        }
    }
    &__address{
        margin-right: rem(75px);
        @include mq($until: xl){
            margin-right: rem(25px);
        }
    }
    &__subhead{
        margin-bottom: rem(35px);
        font-size: rem(16px);
        color: $color-main;
    }
    &__map{
        position: absolute;
        right: 0;
        top: -5vw;
        width: 60%;
        @include mq($until: xxl){
            width: 50%;
        }
        @include mq($until: xl){
            width: 45%;
        }
        @include mq($until: md) {
            position: static;
            width: 100%;
            margin-top: 30px;
            padding: 0;
        }
        iframe{
            box-shadow: 0 0 10px rgba(0,0,0,0.3);
        }
    }
    &__box{
        display: flex;
        align-items: flex-start;
        margin-bottom: rem(35px);
        &--no-margin{
            margin-bottom: 0;
        }
        img{
            margin-right: 8px;
        }
        &-content{
            display: flex;
            flex-direction: column;
            p,a{
                margin: 0 0 5px 0;
                font-size: rem(16px);
                color: $color-font-black;
                line-height: 1.5;
            }
            a:hover{
                color: $color-main;
            }
        }
    }
    &__link{
        display: flex;
        align-items: center;
        &:hover span{
            color: $color-main;
        }
        span{
            font-family: $font-family-extra;
            font-size: rem(15px);
            color: #000000;
            margin-left: 5px;
            transition: color .3s ease-in-out;
        }
    }
}