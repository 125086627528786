.blood-donation {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 15px;
    row-gap: 25px;

    &__link {
        background-color: $color-extra;
        border-radius: 15px;
        padding: 20px;
        position: relative;
        display: block;
        color: $color-font-black;
        transition: all .3s;

        img {
            position: absolute;
            top: -25px;
            right: -10px;

            @include mq($until: sm) {
                max-width: 50px;
            }
        }

        div {
            text-align: right;
            color: inherit;
        }

        &:hover {
            background-color: $color-main;
            color: white;
        }
    }

    @include mq($until: md) {
        grid-template-columns: 1fr;
    }
}
