// =========================
// Modules and Variables
// =========================

/**
    mq() takes up to three optional parameters:

    $from: inclusive min-width boundary
    $until: exclusive max-width boundary
    $and: additional custom directives
 */
$mq-breakpoints: (
        xs:  576px,
        sm:  768px,
        md:  992px,
        lg: 1200px,
        xl: 1500px,
        xxl: 1920px
);


$color-main: #dd1f26;
$color-extra: #f3f2f1;
$color-font-white: #fff;
$color-font-black: #000000;
$color-font-grey: #58595b;

// Font weights
$fw-light: 300;
$fw-regular: 400;
$fw-medium: 500;
$fw-semi-bold: 600;
$fw-bold: 700;
$fw-extra-bold: 700;
$fw-black: 900;


// Font Family
$font-family: 'Lato', serif;
$font-family-extra: 'BreeSerif', serif;

// Base Font for HTML
$base-font-size: 16px;

// Paragraph Styles
$paragraph-color      : #505050;
$paragraph-size       : rem(17px);
$paragraph-line-height: 1.5em;
$paragraph-margin     : 20px;

// Header Styles
$header-color      : $color-font-black;
$header-size       : rem(33px);
$header-line-height: 1.25em;
$header-margin     : $paragraph-margin * 1.4;

// Blockquote Styles
$blockquote-color      : #505050;
$blockquote-size       : rem(23px);
$blockquote-line-height: 1.5em;
$blockquote-margin     : $paragraph-margin * 1.5;

// Links
$link-color: $color-main;

// hr
$hr-margin: 30px;

// Type Misc
$font-weight-bold: 700;
$border-color: #DADADA;