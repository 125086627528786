.header{
    display: flex;
    flex-direction: column;
    &__logo{
        @include mq($until: xl){
            width: 35%;
        }
        @include mq($until: md) {
            width: 40%;
        }
        @include mq($until: sm) {
            width: 60%;
        }
        @include mq($until: xs) {
            width: 80%;
        }
    }
    &__top, &__info{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &__top{
        min-height: 150px;
        @include mq($until: lg){
            align-items: flex-start;
            flex-direction: column;
            padding-top: 15px;
            padding-bottom: 15px;
            border-bottom: 2px solid $color-main;
        }
    }
    &__info{
        width: 48%;
        @include mq($until: xl){
            width: 60%;
        }
        @include mq($until: lg){
            margin-top: 15px;
            width: 100%;
        }
        @include mq($until: xs) {
            flex-wrap: wrap;
        }
    }
    &__social{
        display: flex;
        align-items: center;
        &:hover span{
            color: $color-main;
        }
        span{
            font-size: rem(15px);
            font-family: $font-family-extra;
            color: $color-font-grey;
            margin-right: 10px;
            transition: color .3s ease-in-out;
        }
    }
    &__bip{
      @include mq($until: sm){
          max-width: 70px;
      }
    }

    /* nav */
    &__nav{
        background: $color-main;
        min-height: 55px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &__menuList{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0;
        width: 100%;
    }
    &__menuItem{
        margin: 0;
        padding: 0;
        &::before{
            display: none;
        }
    }
    &__menuLink{
        font-family: $font-family-extra;
        font-size: rem(18px);
        letter-spacing: 0.5px;
        display: flex;
        align-items: center;
        min-height: 55px;
        background-color: $color-main;
        padding: 0 15px;
        color: $color-font-white;
        transition: background-color .3s ease-in-out, color .3s ease-in-out;
        @include mq($until: xxl) {
            font-size: 1rem;
        }
        @include mq($until: xl){
            font-size: 0.9rem;
            padding: 0 5px;
        }
        &--hasSubmenu::after{
            font-family: "Font Awesome 5 Free";
            content: '\f0dd';
            margin-left: 5px;
            transition: color .3s ease-in-out;
            color: $color-font-white;
            position: relative;
            top: -2px;
            @include mq($until: xl){
                display: none;
            }
        }
        &:hover, &:active, &--active, &--open{
            background-color: white;
            color: $color-main;
            &::after{
                color: $color-main;
            }
        }
    }
}