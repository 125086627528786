.results{
  &__header{
    margin-top: 50px !important;
    margin-bottom: 50px;
    @include mq($until: sm){
      margin-top: 30px !important;
      margin-bottom: 30px;
    }
    b{
      color: $color-main;
    }
  }
}
.ss-result{
  &__badge{
    font-size: rem(12px);
    margin-left: 5px;
  }
  &__image{
    display: none;
  }
}