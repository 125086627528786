.post{
    margin: 0 auto;
    width: 100%;
    &__content{
        display: flex;
        justify-content: space-between;
        margin-top: 3vw;
        @include mq($until: md) {
            flex-direction: column;
        }
    }
    &__img{
        position: sticky;
        top: 30px;
        width: 40%;
        height: 45vw;
        background-repeat: no-repeat;
        background-position: top center;
        background-size: contain;
        z-index: 1;
        @include mq($until: md) {
            width: 70%;
            order: 0;
            background-position: top left;
            margin-bottom: 15px;
        }
        @include mq($until: xs) {
            width: 100%;
        }
    }
    &__text{
        width: 60%;
        padding-right: 50px;
        @include mq($until: md) {
            width: 100%;
            order: 1;
            padding-right: 0;
        }
    }
}