.home{
    &__banners{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;
        @include mq($until: sm) {
            flex-wrap: wrap;
        }
    }
    &__banner{
        border-radius: 8px;
        overflow: hidden;
        @include mq($until: xxl){
            width: 48%;
        }
        @include mq($until: sm) {
            width: 100%;
            margin-bottom: 15px;
        }
    }
    &__buttons{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 1rem;
        @include mq($until: md) {
            grid-template-columns: 1fr 1fr;
        }
        @include mq($until: xs) {
            grid-template-columns: 1fr;
        }
        .button{
            width: 100%;
            max-width: unset;
            justify-content: flex-start;
            &__name{
                font-size: rem(25px);
                @include mq($until: lg){
                    font-size: 1.3rem;
                }
            }
        }
    }
    &__news{
        margin: 70px 0;
        h2{
            margin-bottom: 30px;
        }
        .button-wrapper{
            margin-top: 30px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
    }
    &__partners{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 100px 0;
        h2{
            width: 15%;
        }
        @include mq($until: lg){
            flex-direction: column;
            align-items: flex-start;
        }
    }
}
.localization{
    margin-top: 40px;
    margin-bottom: 200px;
    background-image: url('../../images/banners/baner5-bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: flex-end;
    border-radius: 8px;
    padding: 10px 30px;
    @include mq($until: xxl){
        background-position: -15vw 0;
    }
    @include mq($until: lg) {
        background-position: -27vw 0;
    }
    @include mq($until: md) {
        background-image: none;
        background-color: $color-extra;
        padding: 0;
        justify-content: space-between;
        margin-bottom: 50px;
    }
    @include mq($until: xs) {
        flex-direction: column;
    }
    &__text{
        display: flex;
        flex-direction: column;
        position: absolute;
        left: 26vw;
        top: calc(50% - 3vw);
        @include mq($until: lg) {
            left: 20vw;
        }
        @include mq($until: md) {
            position: static;
            padding-top: 15px;
            padding-left: 15px;
        }
        @include mq($until: xs) {
            width: 100%;
        }
    }
    &__slogan{
        font-family: $font-family-extra;
        font-size: 2vw;
        color: $color-font-black;
        line-height: 1.5;
        @include mq($until: md) {
            font-size: 1.3rem;
        }
        &--link{
            text-transform: uppercase;
            color: $color-main;
        }
    }
}